import { Button } from "@nextui-org/react";
import React from "react";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import { Download } from "react-iconly";
import { capitalize } from "../../helpers/tableHelpers";

export const ExcelButton = ({ id, data, fileName, columns }) => {
  return (
    <ExcelFile
      filename={fileName}
      element={
        <button
          style={{
            cursor: "pointer",
            width: "36px",
            height: "36px",
            background: "#f31260",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            borderRadius: "33%",
          }}
        >
          <Download set="bold" primaryColor="white" />
        </button>
      }
    >
      <ExcelSheet data={data} name={fileName}>
        {columns.map((column) => (
          <ExcelColumn key={column} label={capitalize(column)} value={column} />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
};
