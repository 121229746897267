import React, { useState } from "react";
import {
  Table,
  Badge,
  Input,
  Container,
  Card,
  Spacer,
  Button,
} from "@nextui-org/react";
import { ActionCell } from "../components/table/ActionCell";
import SearchIcon from "../components/icons/SearchIcon";
import { capitalize, findInArray } from "../helpers/tableHelpers";
import { ExcelButton } from "../components/table/ExcelButton";
import { Download } from "react-iconly";

export const Usuarios = () => {
  const columns = ["name", "role", "status"];
  const originalData = [
    {
      id: 1,
      name: "Tony Reichert",
      role: "CEO",
      team: "Management",
      status: "active",
      age: "29",
      avatar: "https://i.pravatar.cc/150?u=a042581f4e29026024d",
      email: "tony.reichert@example.com",
    },
    {
      id: 2,
      name: "Zoey Lang",
      role: "Technical Lead",
      team: "Development",
      status: "paused",
      age: "25",
      avatar: "https://i.pravatar.cc/150?u=a042581f4e29026704d",
      email: "zoey.lang@example.com",
    },
    {
      id: 3,
      name: "Jane Fisher",
      role: "Senior Developer",
      team: "Development",
      status: "active",
      age: "22",
      avatar: "https://i.pravatar.cc/150?u=a04258114e29026702d",
      email: "jane.fisher@example.com",
    },
    {
      id: 4,
      name: "William Howard",
      role: "Community Manager",
      team: "Marketing",
      status: "vacation",
      age: "28",
      avatar: "https://i.pravatar.cc/150?u=a048581f4e29026701d",
      email: "william.howard@example.com",
    },
    {
      id: 5,
      name: "Kristen Copper",
      role: "Sales Manager",
      team: "Sales",
      status: "active",
      age: "24",
      avatar: "https://i.pravatar.cc/150?u=a092581d4ef9026700d",
      email: "kristen.cooper@example.com",
    },
  ];

  const [datos, setDatos] = useState(originalData);

  const handleSearch = (e) => {
    const value = e.target.value;
    const filteredData = findInArray(originalData, value);
    setDatos(filteredData);
  };

  return (
    <Card>
      <Card.Header>
        <Container gap={0} display="flex" direction="row" justify="flex-end">
          <ExcelButton
            id="excelButton"
            data={originalData}
            fileName="usuarios"
            columns={columns}
          />
          <Spacer x={2} />
          <Input
            clearable
            contentLeft={
              <SearchIcon fill="var(--nextui-colors-accents6)" size={16} />
            }
            placeholder="Buscar..."
            bordered
            color="secondary"
            onChange={handleSearch}
          />
        </Container>
      </Card.Header>
      <Card.Divider />
      <Card.Body>
        <Table
          css={{
            height: "auto",
            minWidth: "100%",
          }}
          color="secondary"
          bordered
          striped
          lined
          headerLined
          selectionMode="single"
        >
          <Table.Header>
            {columns.map((column) => (
              <Table.Column key={column} align={"left"}>
                {capitalize(column)}
              </Table.Column>
            ))}
            <Table.Column key="actions" width={120} align={"left"}>
              ACCIONES
            </Table.Column>
          </Table.Header>
          <Table.Body items={datos}>
            {(item) => (
              <Table.Row key={item.id}>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.role}</Table.Cell>
                <Table.Cell>
                  <Badge size="sm" variant="flat" color="success">
                    Neutral
                  </Badge>
                </Table.Cell>
                <Table.Cell>
                  <ActionCell onView={() => console.log("viewwwww", item)} />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Pagination
            shadow
            noMargin
            align="center"
            rowsPerPage={3}
            total={Math.ceil(datos.length / 3)}
            onPageChange={(page) => console.log({ page })}
          />
        </Table>
      </Card.Body>
    </Card>
  );
};
