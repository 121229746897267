import { Col, Row, Tooltip } from "@nextui-org/react";
import React from "react";
import IconButton from "../shared/IconButton";
import EyeIcon from "../icons/EyeIcon";
import EditIcon from "../icons/EditIcon";
import DeleteIcon from "../icons/DeleteIcon";

export const ActionCell = ({ onView, onEdit, onDelete }) => {
  return (
    <Row justify="center" align="center">
      <Col css={{ d: "flex" }}>
        <Tooltip content="Ver" color={"invert"}>
          <IconButton onClick={onView}>
            <EyeIcon size={20} fill="#979797" />
          </IconButton>
        </Tooltip>
      </Col>
      <Col css={{ d: "flex" }}>
        <Tooltip content="Editar" color={"invert"}>
          <IconButton onClick={onEdit}>
            <EditIcon size={20} fill="#979797" />
          </IconButton>
        </Tooltip>
      </Col>
      <Col css={{ d: "flex" }}>
        <Tooltip content="Eliminar" color="error" onClick={onDelete}>
          <IconButton>
            <DeleteIcon size={20} fill="#FF0080" />
          </IconButton>
        </Tooltip>
      </Col>
    </Row>
  );
};
