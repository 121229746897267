import React from "react";
import { Button, Card, Col, Grid, Row, Text } from "@nextui-org/react";
import CardFormulario from "./CardFormulario";

const formularios = [
  {
    CodFormulario: 202210111757102030,
    Formulario: "PAUTA DE INSPECCIÓN PREVENTIVA ESPESADOR",
    Finalizada: true,
    PermiteModificacion: true,
    FechaCreacion: "2021-10-11",
    Imagen:
      "https://images.unsplash.com/photo-1610398000003-8b8f2b2b2f1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    CodEstado: 1,
    LastModified: 202210111757102030,
  },
  {
    CodFormulario: 202210111757102031,
    Formulario: "PAUTA DE INSPECCIÓN PREVENTIVA ESPESADOR",
    Finalizada: false,
    PermiteModificacion: false,
    FechaCreacion: "2021-10-11",
    Imagen:
      "https://images.unsplash.com/photo-1610398000003-8b8f2b2b2f1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    CodEstado: 1,
    LastModified: 202210111757102031,
  },
  {
    CodFormulario: 202210111757102032,
    Formulario: "PAUTA DE INSPECCIÓN PREVENTIVA ESPESADOR",
    Finalizada: false,
    PermiteModificacion: true,
    FechaCreacion: "2021-10-11",
    Imagen:
      "https://images.unsplash.com/photo-1610398000003-8b8f2b2b2f1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    CodEstado: 1,
    LastModified: 202210111757102032,
  },
  {
    CodFormulario: 202210111757102033,
    Formulario: "PAUTA DE INSPECCIÓN PREVENTIVA ESPESADOR",
    Finalizada: false,
    PermiteModificacion: true,
    FechaCreacion: "2021-10-11",
    Imagen:
      "https://images.unsplash.com/photo-1610398000003-8b8f2b2b2f1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    CodEstado: 1,
    LastModified: 202210111757102033,
  },
];

export const Formularios = () => {
  return (
    <Card>
      <Card.Header>
        <Col>
          <Text h3>Formularios</Text>
        </Col>
        <Col>
          <Row justify="flex-end">
            <Button auto rounded shadow color="success">
              <Text
                css={{ color: "inherit" }}
                size={12}
                weight="bold"
                transform="uppercase"
              >
                Crear
              </Text>
            </Button>
          </Row>
        </Col>
      </Card.Header>
      <Card.Body>
        <Grid.Container gap={2}>
          {formularios.map((formulario) => (
            <Grid xs={12} sm={6} md={4} lg={3}>
              <CardFormulario formulario={formulario} />
            </Grid>
          ))}
        </Grid.Container>
      </Card.Body>
    </Card>
  );
};
