
const findInArray = (arr, value) => {
    value = String(value).toLowerCase();
    return arr.filter(o =>
        Object.entries(o).some(entry =>
            String(entry[1]).toLowerCase().includes(value)
        )
    );
}

const capitalize = (s) => s.replace(/./, (c) => c.toUpperCase());

export { findInArray, capitalize };