import React from "react";
import {
  Navbar,
  Avatar,
  Dropdown,
  Text,
  Switch,
  Link,
} from "@nextui-org/react";
import { useLocation, useNavigate } from "react-router-dom";
import useDarkMode from "use-dark-mode";
import SunIcon from "../icons/SunIcon";
import MoonIcon from "../icons/MoonIcon";

export const Header = () => {
  const darkMode = useDarkMode(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  console.log(pathname);

  const items = [
    {
      key: "/",
      title: "Dashboard",
      icon: "home",
      onClick: () => navigate("/"),
    },
    {
      key: "/formularios",
      title: "Formularios",
      icon: "mail",
      onClick: () => navigate("/formularios"),
    },
    {
      key: "/usuarios",
      title: "Usuarios",
      icon: "users",
      onClick: () => navigate("/usuarios"),
    },
  ];

  return (
    <Navbar isBordered variant="floating">
      <Navbar.Toggle showIn="md" />
      <Navbar.Brand
        css={{
          "@xs": {
            w: "12%",
          },
        }}
      >
        {/* <AcmeLogo /> */}
        <Text
          h1
          size={30}
          css={{
            textGradient: "45deg, $blue600 -20%, $pink600 50%",
          }}
          weight="bold"
        >
          Encuestas
        </Text>
      </Navbar.Brand>
      <Navbar.Content
        enableCursorHighlight
        activeColor="secondary"
        hideIn="md"
        variant="highlight"
      >
        {items.map((item) => (
          <Navbar.Link
            key={item.key}
            onClick={item.onClick}
            isActive={pathname === item.key}
          >
            {item.title}
          </Navbar.Link>
        ))}
      </Navbar.Content>

      <Navbar.Content
        css={{
          "@xs": {
            w: "12%",
            jc: "flex-end",
          },
        }}
      >
        <Navbar.Item>
          <Switch
            checked={darkMode.value}
            color="secondary"
            iconOn={<SunIcon filled />}
            iconOff={<MoonIcon filled />}
            onChange={() => darkMode.toggle()}
          />
        </Navbar.Item>
        {/* dropdown usuario */}
        <Dropdown placement="bottom-right">
          <Navbar.Item>
            <Dropdown.Trigger>
              <Avatar
                bordered
                as="button"
                color="secondary"
                size="md"
                src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
              />
            </Dropdown.Trigger>
          </Navbar.Item>
          <Dropdown.Menu
            aria-label="User menu actions"
            color="secondary"
            onAction={(actionKey) => {
              navigate(actionKey);
            }}
          >
            <Dropdown.Item key="#" css={{ height: "$18" }}>
              <Text b color="inherit" css={{ d: "flex" }}>
                Logeado como:
              </Text>
              <Text b color="inherit" css={{ d: "flex" }}>
                diego@example.com
              </Text>
            </Dropdown.Item>
            <Dropdown.Item key="/perfil" withDivider>
              Perfil
            </Dropdown.Item>
            <Dropdown.Item
              key="/logout"
              withDivider
              color="error"
              onClick={() => navigate("/login")}
            >
              Salir
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* fin dropdown usuario */}
      </Navbar.Content>

      {/* responsive menu */}
      <Navbar.Collapse>
        {items.map((item) => (
          <Navbar.CollapseItem
            key={item.key}
            activeColor="secondary"
            isActive={pathname === item.key}
            css={{
              color: item.key === "/" ? "error" : "",
            }}
          >
            <Link color="inherit" onClick={item.onClick}>
              {item.title}
            </Link>
          </Navbar.CollapseItem>
        ))}
      </Navbar.Collapse>
      {/* fin responsive menu */}
    </Navbar>
  );
};
