import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { createTheme, NextUIProvider } from '@nextui-org/react';
import useDarkMode from 'use-dark-mode';
import './App.css';
import { LayoutAdmin } from './components/layout/LayoutAdmin';
import { Dashboard } from './pages/Dashboard';
import { Login } from './pages/Login';
import { NotFound } from './pages/NotFound';
import { Usuarios } from './pages/Usuarios';
import { FormFormulario, Formularios } from './pages/Formularios';

const lightTheme = createTheme({
  type: 'light',
});

const darkTheme = createTheme({
  type: 'dark',
});

function App() {
  const darkMode = useDarkMode(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  return (
    <NextUIProvider theme={darkMode.value ? darkTheme : lightTheme}>
      <Routes>
        <Route
          exact
          path="/login"
          element={
            isAuthenticated ? <Navigate to="/" replace /> : <Login />
          }
        />
        <Route
          element={isAuthenticated ? <LayoutAdmin /> : <Navigate to="/login" />}
        >
          <Route path="/" element={<Dashboard />} />
          <Route path="/usuarios" element={<Usuarios />} />
          <Route path="/formularios" element={<Formularios />}>
            <Route path="new" element={<FormFormulario />} />
            <Route path="edit/:id" element={<FormFormulario />} />
          </Route>
          {/* {routes.map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} />
        ))} */}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </NextUIProvider>
  );
}

export default App;
