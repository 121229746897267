import { Card, Col, Row, Button, Text, Spacer } from "@nextui-org/react";

export const CardFormulario = ({ formulario }) => (
  <Card css={{ w: "100%", h: "400px" }}>
    <Card.Header
      css={{ position: "absolute", bgBlur: "#ffffff66", zIndex: 1, top: 0 }}
    >
      <Col>
        <Text h6 transform="uppercase" color="black">
          {formulario.Formulario}
        </Text>
      </Col>
    </Card.Header>
    <Card.Body css={{ p: 0 }}>
      <Card.Image
        src={`${process.env.PUBLIC_URL}/assets/images/undraw_collaboration_re_vyau.svg`}
        width="100%"
        height="100%"
        objectFit="cover"
        alt="Card example background"
      />
    </Card.Body>
    <Card.Footer
      isBlurred
      css={{
        position: "absolute",
        bgBlur: "#ffffff66",
        borderTop: "$borderWeights$light solid rgba(255, 255, 255, 0.2)",
        bottom: 0,
        zIndex: 1,
      }}
    >
      <Row>
        <Col>
          {/* <Text color="#000" size={12}>
            Available soon.
          </Text>
          <Text color="#000" size={12}>
            Get notified.
          </Text> */}
        </Col>
        <Col>
          <Row justify="flex-end">
            <Button
              auto
              rounded
              shadow
              color="secondary"
              disabled={
                formulario.Finalizada || !formulario.PermiteModificacion
              }
            >
              <Text
                css={{ color: "inherit" }}
                size={12}
                weight="bold"
                transform="uppercase"
              >
                Editar
              </Text>
            </Button>
            <Spacer x={1} />
            <Button auto rounded shadow color="error">
              <Text
                css={{ color: "inherit" }}
                size={12}
                weight="bold"
                transform="uppercase"
              >
                Eliminar
              </Text>
            </Button>
          </Row>
        </Col>
      </Row>
    </Card.Footer>
  </Card>
);
export default CardFormulario;
