import React from "react";
import { Container, Spacer, styled } from "@nextui-org/react";
import { Outlet } from "react-router-dom";
import { Header } from "./Header";

const Box = styled("div", {
  boxSizing: "border-box",
});

export const LayoutAdmin = () => (
  <>
    <Header />
    {/* <Box css={{ px: "$12", mt: "$8", "@xsMax": { px: "$10" } }}>
      <Outlet />
    </Box> */}
    <Spacer />
    <Container responsive={true}>
      <Outlet />
    </Container>
  </>
);
